import classnames from 'classnames';

import componentMap from '../../components';
import withErrorBoundary from '../ErrorBoundary';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

import type { FeatureContainerProps } from 'common-types/types/FeatureContainerProps';

import STYLES from './FeatureContainer.module.scss';

const FeatureContainer = (props: FeatureContainerProps): JSX.Element => {
  const { advert, components, hasStrevda, headerComponents } = props;

  let content = (
    <>
      {components.map(
        ({
          componentProps,
          dataIdConfigSource,
          extraClasses,
          id,
          isCriticalComponent,
          isNoMargin,
          name,
        }) => {
          const PageComponent = componentMap[name];
          const componentIdentifier = id || name;
          if (!PageComponent) return null;
          return (
            <ErrorBoundary
              key={componentIdentifier}
              componentName={`feature-container-component.${name}`}
              metadata={{ isCriticalComponent }}
            >
              <div
                id={componentIdentifier}
                data-id-config-source={
                  dataIdConfigSource || 'static--not-platform-component'
                }
                className={classnames(extraClasses, {
                  [STYLES.FeatureItem]: !isNoMargin,
                  [STYLES.FeatureItemNoMargin]: isNoMargin,
                })}
              >
                <PageComponent {...componentProps} />
              </div>
            </ErrorBoundary>
          );
        },
      )}
    </>
  );

  if (advert && componentMap[advert.name]) {
    const PageComponent = componentMap[advert.name];
    const componentIdentifier = advert.id || advert.name;
    content = (
      <ErrorBoundary
        key={componentIdentifier}
        componentName={`feature-container-component.${advert.name}`}
      >
        <div id={componentIdentifier} className={STYLES.FeatureItem}>
          <PageComponent {...advert.componentProps}>{content}</PageComponent>
        </div>
      </ErrorBoundary>
    );
  }

  return (
    <div className={STYLES.FeatureContainer}>
      {headerComponents && (
        <div>
          {headerComponents.map(
            ({
              componentProps,
              dataIdConfigSource,
              id,
              isCriticalComponent,
              isFullWidth,
              name,
            }) => {
              const PageComponent = componentMap[name];
              const componentIdentifier = id || name;
              if (!PageComponent) return null;
              return (
                <ErrorBoundary
                  key={componentIdentifier}
                  componentName={`feature-container-component.${name}`}
                  metadata={{ isCriticalComponent }}
                >
                  <div
                    id={componentIdentifier}
                    data-id-config-source={
                      dataIdConfigSource || 'static--not-platform-component'
                    }
                    className={
                      isFullWidth ? STYLES.FullWidth : STYLES.ItemWidth
                    }
                  >
                    <PageComponent {...componentProps} />
                  </div>
                </ErrorBoundary>
              );
            },
          )}
        </div>
      )}
      <div
        className={hasStrevda ? STYLES.MainSectionWithAD : STYLES.MainSection}
      >
        <div className={STYLES.Main} id="feature-container-main">
          {content}
        </div>
        {hasStrevda ? (
          <aside id="strevda-mpu-wrapper" className={STYLES.Sidebar} />
        ) : null}
      </div>
    </div>
  );
};

export default withErrorBoundary(
  FeatureContainer,
  'feature-container-component',
);
